import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { SkeletonBlurb } from 'components/Containers';
import AdmittedAtModal from 'components/modals/AdmittedAtModal';
import PatientStatusUpdateModal from 'components/modals/PatientStatusUpdateModal';
import ServiceRefusalModal from 'components/modals/ServiceRefusalModal';
import Button from 'components/shared/Button';
import ButtonGroup from 'components/shared/ButtonGroup';
import { Flags } from 'constants/flags';
import { dateAtTime } from 'lib/date';
import Episode from 'models/Episode';
import LocationEpisode from 'models/LocationEpisode';
import Profile, { PERMISSIONS } from 'models/Profile';
import ServiceRefusal from 'models/reviews/ServiceRefusal';
import { colors } from 'styles/theme/colors';
import { BodySmallBold, Label } from 'styles/typography';
import ThumbsDownIcon from 'svg/ThumbsDownIcon';
import ThumbsUpIcon from 'svg/ThumbsUpIcon';
import UnsureIcon from 'svg/UnsureIcon';

import useInvalidatedPatientQueries from '../useInvalidatedPatientQueries';

enum StatusResponse {
  YES = 'Yes',
  NO = 'No',
  UNSURE = 'Unsure',
  EXPIRED = 'Expired',
}

enum PatientStatusUpdateModalType {
  UPDATE_PROGRESS = 'UpdateProgress',
  ADMIT_TO_OLIO_NO_UPDATE = 'AdmitInOlioNoUpdate',
  ADMIT_TO_OLIO_UPDATE = 'AdmitInOlioUpdate',
}

const RefuseServiceButton = styled(Button)`
  border: 1px solid var(--black);
`;

const SkeletonBlank = styled(SkeletonBlurb)<{
  $width: number;
  $height: number;
}>`
  border-radius: ${({ $height }) => $height + 'px'};
  height: ${({ $height }) => $height + 'px'};
  width: ${({ $width }) => $width + 'px'};
  margin-right: 8px;
`;

type PatientStatusType = {
  episode: Episode;
  locationEpisode: LocationEpisode;
  patientName?: string;
  profile: Profile;
};

export const SkeletonPatientStatus = () => {
  return (
    <ProgressContainer>
      <PatientStatusContainer>
        <Status>
          <SkeletonBlank $height={15} $width={150} />
          <SkeletonBlank $height={24} $width={80} />
        </Status>
        <SkeletonBlank $height={12} $width={130} />
      </PatientStatusContainer>
    </ProgressContainer>
  );
};

export default function PatientStatus(props: PatientStatusType) {
  const { episode, locationEpisode, patientName, profile } = props;

  const [showServiceRefusal, setShowServiceRefusal] = useState(false);
  const [showPatientStatusUpdate, setShowPatientStatusUpdate] = useState(false);

  const invalidatePatientQueries = useInvalidatedPatientQueries();

  const STATUS_MAP = {
    [StatusResponse.YES]: {
      icon: ThumbsUpIcon,
      label: 'On track',
      color: colors.accentGreen,
      textColor: colors.white,
    },
    [StatusResponse.NO]: {
      icon: ThumbsDownIcon,
      label: 'Off track',
      color: colors.accentRed,
      textColor: colors.white,
    },
    [StatusResponse.UNSURE]: {
      icon: UnsureIcon,
      label: 'Unsure',
      color: colors.accentYellow,
      textColor: colors.black75,
    },
    [StatusResponse.EXPIRED]: {
      icon: undefined,
      label: 'Needs update',
      color: colors.black50,
      textColor: colors.white,
    },
  };

  const { onTrack, onTrackUpdatedAt, statusOutOfDate } = locationEpisode;
  const status = statusOutOfDate ? STATUS_MAP[StatusResponse.EXPIRED] : STATUS_MAP[onTrack as StatusResponse];
  const Icon = status?.icon;
  const chipColor = onTrack === StatusResponse.UNSURE ? 'black' : 'white';

  const eligibleForProgressUpdate = locationEpisode.rehabInformation.eligibleForProgressUpdate;
  const canRefuseService =
    profile.has(PERMISSIONS.reviewServiceRefusalCreate, { episode, locationEpisode }) &&
    locationEpisode.rehabInformation?.latestRehabFacilityType === 'HHA' &&
    locationEpisode.id &&
    locationEpisode.currentRehabState.queue;
  const canCreateProgressUpdate = profile.has(PERMISSIONS.activityProgressUpdateCreate, { episode, locationEpisode });
  const canAdmitPatient =
    profile.has(PERMISSIONS.rehabStateAdmissionCreate, { episode, locationEpisode }) &&
    (canCreateProgressUpdate || locationEpisode.inQueue);
  const hasActiveRefusal = !!locationEpisode.reviews.find((r) => r.pending && r instanceof ServiceRefusal); // used to reference r.active
  const progressUpdateDisabled = !locationEpisode.progressTemplate.id || !eligibleForProgressUpdate || hasActiveRefusal;
  const hasThreeLanesEnabled = profile.hasFlag(Flags.ThreeLanes);
  const canMovePatientIntoTreatment =
    canCreateProgressUpdate || (locationEpisode.inQueue && canAdmitPatient && hasThreeLanesEnabled);
  const canSeePatientActions = canRefuseService || canMovePatientIntoTreatment;

  const patientStatusButtonText =
    !locationEpisode.progressTemplate || locationEpisode.inTreatment || locationEpisode.discharged
      ? 'Update Progress'
      : hasThreeLanesEnabled && locationEpisode.inQueue
        ? 'Admit in Olio'
        : 'Start Treatment';

  const patientStatusUpdateModalType = useMemo(() => {
    if (locationEpisode.inQueue && hasThreeLanesEnabled) {
      if (canCreateProgressUpdate) {
        return PatientStatusUpdateModalType.ADMIT_TO_OLIO_UPDATE;
      } else if (canAdmitPatient) {
        return PatientStatusUpdateModalType.ADMIT_TO_OLIO_NO_UPDATE;
      }
    }
    if (canCreateProgressUpdate) {
      return PatientStatusUpdateModalType.UPDATE_PROGRESS;
    }
    return null;
  }, [locationEpisode, canCreateProgressUpdate, canAdmitPatient, hasThreeLanesEnabled]);

  if (
    !(hasThreeLanesEnabled && canAdmitPatient) &&
    (!locationEpisode.latest ||
      locationEpisode.archived ||
      (!canRefuseService && !canCreateProgressUpdate && (!locationEpisode.onTrack || !eligibleForProgressUpdate)))
  ) {
    return <></>;
  }
  return (
    <ProgressContainer>
      {locationEpisode.onTrack && eligibleForProgressUpdate && (
        <PatientStatusContainer>
          <Status>
            <StyledLabel>Patient Progress Status</StyledLabel>
            <Chip $backgroundColor={status.color} $textColor={status.textColor}>
              {Icon && <Icon color={chipColor} width={12} height={12} />}
              {status.label}
            </Chip>
          </Status>
          <LastUpdated>Updated: {dateAtTime(onTrackUpdatedAt)}</LastUpdated>
        </PatientStatusContainer>
      )}

      {canSeePatientActions && (
        <PatientActions>
          {canRefuseService && (
            <RefuseServiceButton
              variant='ghost'
              data-cy='clickable'
              disabled={hasActiveRefusal}
              onClick={() => setShowServiceRefusal(true)}>
              Refuse Services
            </RefuseServiceButton>
          )}
          {(canCreateProgressUpdate || (canAdmitPatient && hasThreeLanesEnabled && locationEpisode.inQueue)) && (
            <Button
              data-cy='clickable'
              onClick={() => setShowPatientStatusUpdate(true)}
              disabled={progressUpdateDisabled}>
              {patientStatusButtonText}
            </Button>
          )}
        </PatientActions>
      )}
      {showServiceRefusal && (
        <ServiceRefusalModal
          setShow={setShowServiceRefusal}
          locationEpisode={locationEpisode}
          patientName={patientName!}
        />
      )}
      {showPatientStatusUpdate &&
        [PatientStatusUpdateModalType.UPDATE_PROGRESS, PatientStatusUpdateModalType.ADMIT_TO_OLIO_UPDATE].includes(
          patientStatusUpdateModalType!
        ) && (
          <PatientStatusUpdateModal
            setShow={setShowPatientStatusUpdate}
            patientName={patientName!}
            locationEpisode={locationEpisode}
            title={patientStatusButtonText!}
          />
        )}
      {showPatientStatusUpdate &&
        patientStatusUpdateModalType === PatientStatusUpdateModalType.ADMIT_TO_OLIO_NO_UPDATE && (
          <AdmittedAtModal
            setShow={setShowPatientStatusUpdate}
            locationEpisodeId={locationEpisode.id}
            patientName={patientName!}
            invalidateData={() => {
              invalidatePatientQueries({
                episode: { id: locationEpisode.episodeId },
                locationEpisode: { id: locationEpisode.id },
                activities: true,
                attachmentsCount: true,
                progressChart: true,
              });
            }}
            title={patientStatusButtonText!}
            toInTreatment
          />
        )}
    </ProgressContainer>
  );
}

const Status = styled.div`
  display: flex;
`;

const StyledLabel = styled(BodySmallBold)`
  margin: auto 8px auto auto;
`;

const LastUpdated = styled(Label)`
  font-style: italic;
  margin-top: 4px;
`;

const PatientStatusContainer = styled.div`
  margin-right: 8px;
`;

const PatientActions = styled(ButtonGroup)`
  margin-left: auto;
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Chip = styled(Label)<{ $backgroundColor: string; $textColor: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $textColor }) => $textColor};
  height: 24px;
  white-space: nowrap;
  border-radius: 12px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 24px;
`;
