export interface CollaborationOptions {
  id: string;
  ownerId: string;
  collaboratorId: string;
  createdAt: string;
  updatedAt: string;
}

function getDefaults(): CollaborationOptions {
  return {
    id: '',
    ownerId: '',
    collaboratorId: '',
    createdAt: '',
    updatedAt: '',
  };
}
export default class Collaboration {
  id: string;
  ownerId: string;
  collaboratorId: string;
  createdAt: string;
  updatedAt: string;

  constructor(options: Partial<CollaborationOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.ownerId = opts.ownerId;
    this.collaboratorId = opts.collaboratorId;
    this.createdAt = opts.createdAt;
    this.updatedAt = opts.updatedAt;
  }
}
